import React from "react"
import SeoComponent from "../components/atoms/SeoComponent"
import GlobalTemplate from "../components/templates/GlobalTemplate"
import PrivacyPolicyBody from "../components/templates/PrivacyPolicyBody"
import { launchingDate } from "../helpers/otherVariables"

const privacy = () => {
  return (
    <>
      <GlobalTemplate>
        <PrivacyPolicyBody launchDate={launchingDate} />
      </GlobalTemplate>
    </>
  )
}

export default privacy

export const Head = () => (
  <SeoComponent pageTitle="Blackness in Full Bloom | Privacy Policy" />
)
