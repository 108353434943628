import React from "react"

const Content = ({ children }) => {
  return (
    <div className="tracking-normal text-xs laptop-standard:text-4lg">
      {typeof children === "string" ? <p>{children}</p> : children}
    </div>
  )
}

export default Content
