import React from "react"
import MainTitle from "../atoms/textStyledComponents/MainTitle"
import SectionTitle from "../atoms/textStyledComponents/SectionTitle"
import Content from "../atoms/textStyledComponents/Content"
import Subtitle from "../atoms/textStyledComponents/Subtitle"
import List from "../atoms/textStyledComponents/List"
import ExternalLink from "../atoms/textStyledComponents/ExternalLink"
import NavLink from "../atoms/textStyledComponents/NavLink"
import MailLink from "../atoms/textStyledComponents/MailLink"
import NavMenu from "../organism/NavMenu"
import MainContainer from "../atoms/textStyledComponents/MainContainer"

const PrivacyPolicyBody = ({ launchDate }) => {
  return (
    <MainContainer>
      <MainTitle>Privacy Policy</MainTitle>

      <SectionTitle>{`Effective Date: ${launchDate}`}</SectionTitle>
      <Content>
        Deutsch LA, Inc. (“Agency”) respects your concerns about privacy. This
        Privacy Notice describes the types of information we collect about
        individuals on{" "}
        <a href="/" target="_blank" rel="noreferrer" className="text-[#ff0000]">
          https://www.blacknessinfullbloom.com
        </a>{" "}
        (the “Site”), how we use the information, with whom we share it, and the
        choices available regarding our use of the information. The Privacy
        Notice also describes the measures we take to safeguard personal
        information, how long we retain it, and how individuals can contact us
        about our privacy practices and to exercise their rights.
      </Content>

      <Content>
        Click on one of the links below to jump to the listed section:
      </Content>
      <NavMenu />

      <SectionTitle>Information We Obtain</SectionTitle>
      <Subtitle>Information You Provide</Subtitle>
      <Content>
        We collect and store personal information (such as name and other
        contact details) that you choose to provide to us through the Site or
        when you contact us. “Personal Information” is information that
        identifies you as an individual or relates to an identifiable
        individual. The types of personal information we collect includes:
      </Content>
      <List>
        {[
          "Contact information, such as your name, title, e-mail address, and telephone number;",
          "Social media handles, and",
          "Other personal information you may submit to us, such as when you contact us.",
        ]}
      </List>

      <Subtitle>Automated Collection of Information</Subtitle>
      <Content>
        When you use the Site, we obtain certain information by automated means,
        such as browser cookies, beacons, device identifiers, server logs, and
        other technologies.
      </Content>
      <Content>
        The information we obtain in this manner may include your:
      </Content>
      <List>
        {[
          "Device IP address,",
          "Domain name,",
          "Identifiers associated with your devices,",
          "Device and operating system type and characteristics,",
          "Web browser characteristics,",
          "Language preferences,",
          "Clickstream data,",
          "Your interactions with the Site (such as the web pages you visit, links you click, and features you use),",
          "The pages that led or referred you to the Site,",
          "Dates and times of access to the Site, and",
          "Other information about your use of the Site.",
        ]}
      </List>
      <Content>
        You can view more information on how we obtain certain information by
        automated means in our{" "}
        <a
          href="/cookies"
          target="_blank"
          rel="noreferrer"
          className="text-[#ff0000]"
        >
          Cookie Notice
        </a>
        , incorporated into this Privacy Notice by this reference.
      </Content>

      <Subtitle>Third-Party Web Analytics Services</Subtitle>
      <Content>
        We use third-party online analytics services, such as Google Analytics.
        The service providers that administer these analytics services use
        cookies and similar technologies to collect and analyze information
        about use of the Site and report on activities and trends and diagnose
        technical issues. To learn more about Google’s practices, please visit{" "}
        <ExternalLink>
          https://www.google.com/policies/privacy/partners/
        </ExternalLink>
        , and opt out of them by downloading the Google Analytics opt-out
        browser add-on, available at{" "}
        <ExternalLink>https://tools.google.com/dlpage/gaoptout</ExternalLink>.
      </Content>

      <SectionTitle>How We Use Personal Information</SectionTitle>
      <Content>
        We use the Personal Information we obtain on the Site for legitimate
        business purposes, including to:
      </Content>
      <List>
        {[
          "Provide our services to you; process, evaluate, and respond to your requests and inquiries; and send administrative information to you, such as changes to our terms, conditions, and policies. We will engage in these activities to manage our contractual relationship with you and/or to comply with a legal obligation.",
          "Market our services to you. We will engage in this activity with your consent or where we have a legitimate interest.",
          "Operate, evaluate, and improve our business (including developing new services; enhancing and improving our services; analyzing our services; managing our communications; performing data analytics and market research; and performing accounting, auditing, and other internal functions). We will engage in these activities to comply with a legal obligation and/or because we have a legitimate interest.",
          "Protect against, identify, and prevent fraud, security breaches, and other criminal activity and risks. We will engage in these activities to comply with a legal obligation and/or because we have a legitimate interest.",
          "Comply with and enforce applicable legal requirements, relevant industry standards, and our policies, including this Privacy Notice and/or Agency’s Terms and Conditions or Terms of Use. We will engage in these activities to comply with a legal obligation and/or because we have a legitimate interest.",
          "Aggregate and/or anonymize Personal Information so that it will no longer be considered personal information. We do so to generate other data for our use, which we may use and disclose for any purpose.",
        ]}
      </List>

      <SectionTitle>Personal Information Sharing</SectionTitle>
      <Content>We share your Personal Information with:</Content>
      <List>
        Our service providers who perform services on our behalf, such as
        hosting this Site or data analytics. We do not authorize our service
        providers to use or disclose the information except as necessary to
        perform services on our behalf or comply with legal requirements.
      </List>
      <Content>
        We also disclose information about you (1) if we are required to do so
        by law or legal process (such as a court order or subpoena), including
        laws outside your country of residence; (2) in response to requests by,
        or cooperate with, government agencies, such as law enforcement
        authorities, including outside of your country of residence; (3) to
        establish, exercise, or defend our legal rights; (4) when we believe
        disclosure is necessary or appropriate to protect our rights, privacy,
        safety, or property and/or that of our affiliates, you, or others; (5)
        in connection with an investigation of suspected or actual illegal
        activity; (6) in connection with the sale or transfer of all or a
        portion of our business, assets, or stock (including in the event of a
        reorganization, dissolution, or liquidation); or (7) otherwise with your
        consent.
      </Content>

      <SectionTitle>Cross-Border Transfer</SectionTitle>
      <Content>
        Your personal information may be processed and stored in any country
        where we have facilities or in which we engage service providers, and,
        by using the Site, you understand your information may be transferred to
        countries outside of your country of residence, including the United
        States, which may have data protection rules that are different from
        those of your country. In certain circumstances, courts, law enforcement
        agencies, regulatory agencies, or security authorities in those other
        countries may be entitled to access your personal information.
      </Content>
      <Content>
        ADDITIONAL INFORMATION REGARDING THE EUROPEAN ECONOMIC AREA (“EEA”):
        Some non-EEA countries are recognized by the European Commission as
        providing an adequate level of data protection according to EEA
        standards (the full list of these countries is available{" "}
        <ExternalLink placeholder="here">
          https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en
        </ExternalLink>
        . For transfers from the EEA to countries not considered adequate by the
        European Commission, we have put in place adequate measures, such as
        standard contractual clauses adopted by the European Commission to
        protect your personal information. You may obtain a copy of the EU’s
        Standard Contractual Clauses{" "}
        <ExternalLink placeholder="here">
          https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en
        </ExternalLink>
        .
      </Content>

      <SectionTitle>Your Rights and Choices</SectionTitle>
      <Content>
        We offer you certain choices in connection with the personal information
        we obtain about you. If you would like to request to review, correct,
        update, suppress, restrict, or delete personal information, to object to
        the processing of personal information, or to receive an electronic copy
        of your personal information for purposes of transmitting it to another
        company (to the extent these rights are provided to you by applicable
        law), please contact us as specified in the{" "}
        <NavLink>How to Contact Us</NavLink> section of this Privacy Notice. We
        will respond to your request consistent with applicable law.
      </Content>
      <Content>
        To help protect your privacy and maintain security, we take steps to
        verify your identity before granting you access to your information. To
        the extent permitted by applicable law, a charge may apply before we
        provide you with a copy of any of your personal information that we
        maintain.
      </Content>
      <Content>
        You can unsubscribe from our marketing mailing lists by following the
        “Unsubscribe” link in our marketing emails. If you would like to
        unsubscribe from any other type of marketing you receive from us, please
        contact us as specified in the <NavLink>How to Contact Us</NavLink>{" "}
        section of this Privacy Notice.
      </Content>

      <SectionTitle>
        Other Online Services and Third-Party Features
      </SectionTitle>
      <Content>
        The Site may provide links to other online services and websites for
        your convenience and information, and may include third-party features
        such as apps, tools, widgets, and plug-ins (e.g., LinkedIn, Instagram,
        Facebook, and Twitter buttons). These services and websites may operate
        independently from us. The privacy practices of these third parties,
        including details on the information they collect about you, are subject
        to their own privacy statements. To the extent any linked online
        services or third-party features are not owned or controlled by us,
        Agency is not responsible for these third parties’ information or other
        practices.
      </Content>

      <SectionTitle>Retention of Personal Information</SectionTitle>
      <Content>
        We retain personal information for as long as needed or permitted in
        light of the purposes for which we obtained it and consistent with
        applicable law. The criteria used to determine our retention periods
        include:
      </Content>
      <List>
        {[
          "The length of time we have an ongoing relationship with you and provide the Site or our services to you;",
          "Whether there is a legal obligation to which we are subject; and",
          "Whether retention is advisable in light of our legal position (such as in regard to applicable statutes of limitation, litigation, or regulatory investigations).",
        ]}
      </List>

      <SectionTitle>How We Protect Personal Information</SectionTitle>
      <Content>
        We seek to use reasonable administrative, technical and physical
        safeguards designed to protect personal information within our
        organization. We hold our employees accountable for complying with
        policies, procedures, and regulations regarding the protection and
        confidentiality of personal information. If you have reason to believe
        that your interaction with us is no longer secure, please immediately
        notify us in accordance with the <NavLink>How to Contact Us</NavLink>{" "}
        section of this Privacy Notice.
      </Content>

      <SectionTitle>Children’s Personal Information</SectionTitle>
      <Content>
        The Site is designed for a general audience and is not directed to
        children under the age of 16. Agency does not knowingly collect or
        solicit personal information from children under the age of 16 through
        the Site. If you believe that a child under the age of 16 may have
        provided us with personal information through the Site, please contact
        us as specified in the <NavLink>How to Contact Us</NavLink> section of
        this Privacy Notice.
      </Content>

      <SectionTitle>Updates to Our Privacy Notice</SectionTitle>
      <Content>
        This Privacy Notice will be updated periodically and without prior
        notice to you to reflect changes in our personal information practices.
        We will indicate at the top of the notice when it was most recently
        updated. Any changes will become effective when we post the revised
        Privacy Notice on the Site.
      </Content>

      <SectionTitle>How to Contact Us</SectionTitle>
      <Content>
        Deutsch LA Inc., located at 12901 W Jefferson Blvd, Los Angeles, CA
        90066, is the company responsible for collection, use, and disclosure of
        your personal information under this Privacy Notice.
      </Content>
      <Content>
        If you wish to exercise any of your privacy rights, or if you wish to
        contact us in relation to this Privacy Notice or any privacy matter,
        including our use of your personal information, please use the following
        contact details:
      </Content>
      <Content>
        <div className="ml-5 laptop-standard:ml-10">
          <strong>
            <p>ATTN: Privacy Lead </p>
          </strong>
          <p>
            <MailLink>contactus@deutsch.com</MailLink>
          </p>
        </div>
      </Content>
      <Content>
        Our Data Protection Officer for the UK and EU may be contacted at:
      </Content>
      <Content>
        <div className="ml-5 laptop-standard:ml-10">
          <p>
            For the UK: <MailLink>UK.DPO@interpublic.com</MailLink>
          </p>
          <p>
            For the EU: <MailLink>GDPR.DPO@interpublic.com</MailLink>
          </p>
        </div>
      </Content>
      <Content>
        You have the right to lodge a complaint with a data protection authority
        for your country or region or where an alleged infringement of
        applicable data protection law occurs. A list of data protection
        authorities is available{" "}
        <ExternalLink placeholder={"here"}>
          http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080
        </ExternalLink>
        .
      </Content>
    </MainContainer>
  )
}

export default PrivacyPolicyBody
