import React from "react"

const ExternalLink = ({ children, placeholder }) => {
  return (
    <a
      className="text-[#ff0000]"
      href={children}
      target="_blank"
      rel="noreferrer"
    >
      <span>{placeholder || children}</span>
    </a>
  )
}

export default ExternalLink
