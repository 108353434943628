import React from "react"

const Subtitle = ({ children }) => {
  return (
    <h5
      className="body1"
      id={`${children.toLowerCase().replace(/(\s|\/)/g, "-")}`}
    >
      <em>
        <strong>{children}</strong>
      </em>
    </h5>
  )
}

export default Subtitle
