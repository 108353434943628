import React from "react"
import NavLink from "../atoms/textStyledComponents/NavLink"

const NavMenu = () => {
  return (
    <ul className="body1 list-disc ml-5 space-y-5 laptop-standard:ml-10">
      <li>
        <NavLink>Information We Obtain</NavLink>
        <ul className="body1 list-square ml-5 space-y-5 mt-5 laptop-standard:ml-10">
          {[
            "Information You Provide",
            "Automated Collection of Information",
            "Third-Party Web Analytics Services",
          ].map((s, i) => (
            <li key={i}>
              <NavLink>{s}</NavLink>
            </li>
          ))}
        </ul>
      </li>
      {[
        "How We Use Personal Information",
        "Personal Information Sharing",
        "Cross-Border Transfer",
        "Your Rights and Choices",
        "Other Online Services and Third-Party Features",
        "Retention of Personal Information",
        "How We Protect Personal Information",
        "Children’s Personal Information",
        "Updates to Our Privacy Notice",
        "How to Contact Us",
      ].map((s, i) => (
        <li key={i}>
          <NavLink>{s}</NavLink>
        </li>
      ))}
    </ul>
  )
}

export default NavMenu

/*
Information We Obtain
  ['Information You Provide',
  'Automated Collection of Information',
  'Third-Party Web Analytics Services ',]
['How We Use Personal Information',
'Personal Information Sharing',
'Cross-Border Transfer',
'Your Rights and Choices',
'Other Online Services and Third-Party Features',
'Retention of Personal Information',
'How We Protect Personal Information',
'Children’s Personal Information',
'Updates to Our Privacy Notice',
'How to Contact Us',]

*/
