import React from "react"

const List = ({ children, itemsTitle }) => {
  return (
    <ul className="body1 list-disc ml-5 space-y-5 laptop-standard:ml-10">
      {Array.isArray(children) ? (
        children.map((child, i) => (
          <li key={i}>
            {itemsTitle && Array.isArray(itemsTitle) && (
              <strong>{itemsTitle[i]} </strong>
            )}
            {child}
          </li>
        ))
      ) : (
        <li>{children}</li>
      )}
    </ul>
  )
}

export default List
