import React from "react"

const MailLink = ({ children }) => {
  return (
    <a
      className="text-[#ff0000]"
      href={`mailto:${children}`}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  )
}

export default MailLink
