import React from "react"

const MainContainer = ({ children }) => {
  return (
    <main className="padding-lateral laptop-standard:px-40 pt-10 pb-15 laptop-standard:pt-25 laptop-standard:pb-40 flex flex-col gap-6">
      {children}
    </main>
  )
}

export default MainContainer
