import React from "react"

const SectionTitle = ({ children }) => {
  return (
    <h4
      className="mt-10"
      id={`${children.toLowerCase().replace(/(\s|\/)/g, "-")}`}
    >
      <strong>{children}</strong>
    </h4>
  )
}

export default SectionTitle
