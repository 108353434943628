import React from "react"

const NavLink = ({ children }) => {
  return (
    <a
      className="text-[#ff0000]"
      href={`#${children.toLowerCase().replace(/(\s|\/)/g, "-")}`}
    >
      {children}
    </a>
  )
}

export default NavLink
